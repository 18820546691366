import styled, { css } from 'styled-components'
// You can use variables from veneer tokens and do something like the below
import Tokens from '@veneer/tokens'
import checkbox from '@veneer/core/dist/scripts/checkbox'
import Button from '@veneer/core/dist/esm/scripts/button'
import { HPXTheme, left, right } from '../../styles/global'
import { withAnalytics } from '@veneer/analytics'

const TrackButton = withAnalytics(Button)

export const Row = styled.div`
  .checkBox {
     > span:last-child {
       margin-${left}: ${Tokens.space3};
       margin-${right}: 0px;
     }
    }
  display: flex;
  flex-direction: row;
  &.m-b-8 {
    margin-bottom: ${Tokens.space2};
  }
  &.m-b-12 {
    margin-bottom: ${Tokens.space3};
  }
  &.m-b-20 {
    margin-bottom: ${Tokens.space5};
  }

  &.m-t-4 {
    margin-top: ${Tokens.space1};
  }
  &.m-t-8 {
    margin-top: ${Tokens.space2};
  }
  &.m-t-12 {
    margin-top: ${Tokens.space3};
  }
  &.m-t-16 {
    margin-top: ${Tokens.space4};
  }
  &.m-t-24 {
    margin-top: ${Tokens.space6};
  }
  &.m-t-32 {
    margin-top: ${Tokens.space8};
  }
  &.m-t-36 {
    margin-top: calc(${Tokens.space8} + ${Tokens.space1});
  }
  .m-l-8 {
    margin-${left}: ${Tokens.space8};
  }
  &.buttons {
    ${HPXTheme.mediaQueries.M} {
      flex-direction: column;
      .m-l-12 {
        margin-${left}: 0px;
        margin-top: 12px;
      }
    }
    ${HPXTheme.mediaQueries.S} {
      flex-direction: column;
      .m-l-12 {
        margin-${left}: 0px;
        margin-top: 12px;
      }
    }
  }
  textarea {
    &::placeholder {
      color:${({ theme }) => theme.colorForegroundLight} !important;
      -webkit-text-fill-color:${({ theme }) =>
        theme.colorForegroundLight} !important;
    };
  }

  .flex-inline {
    display: inline-flex;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  &.m-l-12 {
    margin-${left}: ${Tokens.space3};
  }
  &.max-width {
    max-width: 100%;
  }
`

export const Container = styled.div`
  display: flex;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      margin: ${Tokens.space3};
      padding: ${Tokens.space3};
      background: ${theme.colorBackgroundContainer};
      background-color: ${theme.colorBackgroundContainer};
      border-radius: ${Tokens.borderRadius4};
    `}
`
export const Text = styled.span`
  color: ${({ theme }) => theme.colorForeground};
  &.foreground-light {
    color: ${({ theme }) => theme.colorForegroundLight};
  }
  &.foreground-medium {
    color: ${({ theme }) => theme.colorForegroundMedium};
  }
  margin-bottom: 2px;
  &.medium {
    font-family: ${Tokens.fontTextMedium} !important;
  }
  &.m-r-24 {
    margin-${right}: ${Tokens.space6};
  }
`

export const CheckBox = styled(checkbox)`
  align-items: flex-start !important;
`

export const Form = styled.form`
  margin-bottom: ${Tokens.space8};
  ${({ theme }) =>
    theme.isMobile &&
    css`
      margin: ${Tokens.space3};
      padding: ${Tokens.space3};
      background: ${theme.colorBackgroundContainer};
      background-color: ${theme.colorBackgroundContainer};
      border-radius: ${Tokens.borderRadius4};
    `}
`

export const Label = styled.span`
  flex: 1;
  color: ${({ theme }) => theme.colorForegroundLight || Tokens.colorGray5};
  &.colorGray7 {
    color: ${Tokens.colorGray7};
  }
`

export const Value = styled.span`
  color: ${({ theme }) => theme.colorForeground};
`

export const Line = styled.hr`
  border: 0px;
  border-bottom: 1px solid
    ${({ theme }) => theme.colorForegroundLight || Tokens.colorGray5};
  width: 100%;
  margin: 0px;
`
export const TrackerButton = styled(TrackButton)`
  display: ${(props) => props.show || `block`};
`
