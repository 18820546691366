import { useContext } from 'react'
import { MethoneAPIClient } from '@jarvis/web-support-commons'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import { LocalizationType } from '../types/localization'
interface IContext {
  MethoneClient?: MethoneAPIClient
  localization?: LocalizationType
}

const useMethoneClient = () => {
  const { MethoneClient } = useContext<IContext>(RootContext)
  //const MethoneClient = new MethoneAPIClient(MethoneConfig)
  return MethoneClient
}

export default useMethoneClient
