import React from 'react'
import { ShellProps } from '../src/types/shell'
import { RootComponent } from '../src'
import { ThemeProvider } from '@veneer/theme'
import RootProvider from '@jarvis/react-hpx-support-shared/dist/context/root/RootProvider'
import resources from '../src/assets/locale'
import { DirectionProvider } from '@veneer/core/dist/scripts/direction'
import { JarvisAnalyticsContextProvider } from '@jarvis/jweb-analytics'

import {
  BaseScreenPath,
  Support,
  DefaultScreenName
} from '../src/utils/constants'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

export default function Root({ ...props }) {
  const { v1 } = window.Shell as ShellProps
  const namespace = '@jarvis/react-hpx-support'
  const theme = props?.themeProvider || {}

  return (
    <section id={namespace}>
      <JarvisAnalyticsContextProvider
        initialScreenData={{
          screenName: DefaultScreenName,
          screenPath: `${BaseScreenPath}/${Support}/`,
          activity: `${DefaultScreenName}-v01`
        }}
      >
        <DirectionProvider direction={theme.dir}>
          <ThemeProvider {...theme}>
            <RootProvider
              localization={v1.localization}
              resources={resources}
              MethoneClient={props.MethoneClient}
              store={v1.store}
              useModal={props.useModal}
              refreshProvider={props.refreshProvider}
              authProvider={props.authProvider}
              formatAutoId={props.formatAutoId}
              dir={theme.dir}
              environment={props.environment}
              navigation={props.navigation}
              navigatePath={props.navigatePath}
            >
              <RootComponent {...props} />
            </RootProvider>
          </ThemeProvider>
        </DirectionProvider>
      </JarvisAnalyticsContextProvider>
    </section>
  )
}
