import useMethoneClient from '../api/methone'
import {
  ICaseDetailResponse,
  ICaseRequest,
  ICaseResponse,
  IChatResumeResponse
} from '@jarvis/web-support-commons/dist/methone/cases/types'
import { createProcess } from '@jarvis/web-support-commons'
import useContextAPI from './useContextAPI'
import { useEffect, useState } from 'react'

const useCreateCase = ({ setIsSubmitted, resource, device }) => {
  const MethoneClient = useMethoneClient()
  const {
    environment,
    navigation,
    refreshProvider,
    localization,
    navigatePath
  } = useContextAPI()
  const { isMobile } = environment || {}
  const { refreshComponent } = refreshProvider
  const [caseDetails, setCaseDetails] = useState<ICaseDetailResponse>()
  const [createdCase, setCreatedCase] = useState<ICaseResponse>(null)
  const [creating, setCreating] = useState<boolean>(false)

  useEffect(() => {
    const getCaseDetails = async (CaseID: string) => {
      try {
        setCreating(true)
        const caseDetails = await MethoneClient.getCaseDetails({ CaseID })
        setCaseDetails(caseDetails)
        setCreating(false)
        if (isMobile && resource?.serviceId == 'Call_HP') {
          router(
            navigation,
            'getphonenumber',
            {
              device,
              resource
            },
            device?.DeviceId
          )
        }
        if (setIsSubmitted) setIsSubmitted(true)
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        //setCaseDetails(caseData)
      }
    }
    if (createdCase) getCaseDetails(createdCase?.CaseExchange?.CaseID)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdCase])

  const createCase = async ({
    resource,
    values,
    device,
    profile
  }): Promise<ICaseResponse> => {
    const caseRequest: ICaseRequest = getCreateCaseRequest(
      resource,
      values,
      device,
      profile,
      isMobile
    )
    /** TODO */
    const response = await MethoneClient.createCases(caseRequest)
    if (response?.FaultItemList && response?.FaultItemList.length == 0) {
      if (
        response?.CacheToken &&
        (resource?.serviceId == 'OmniChat' || resource?.serviceId == 'OCChat')
      ) {
        await launchOmniChat({ CacheToken: response?.CacheToken, device })
      }

      refreshComponent({ cases: Math.random() })
    }
    return response
  }

  const router = (navigation, routeName, params, deviceId?) => {
    // const path = deviceId
    //   ? `/support/device/${deviceId}/${routeName}`
    //   : `/support/device/${routeName}`
    const path = deviceId
      ? `/${navigatePath}/${deviceId}/${routeName}`
      : `/${navigatePath}/${routeName}`
    navigation.push(path, {
      type: routeName,
      navBarTitle: 'common.deviceSupport',
      ...params
    })
  }

  const launchOmniChat = async ({ CacheToken, device }) => {
    const response: IChatResumeResponse = await MethoneClient.resumeChat({
      CacheToken
    })
    const country = device?.DeviceInfo?.locale?.country || localization.country
    if (isMobile) {
      router(navigation, 'chat/ominichat', {
        lc: localization?.locale,
        cc: country,
        CacheToken: response?.ChatJWTToken,
        Source: 'HPX'
      })
    } else {
      createProcess(
        `/OCChat /New ${response?.ChatJWTToken} ${localization?.locale} ${country}`,
        false,
        false,
        false
      )
    }

    return response
  }

  return {
    createCase,
    setCreatedCase,
    setCaseDetails,
    setCreating,
    creating,
    caseDetails
  }
}

const _specifyChatChannel = (resource) => {
  if (resource?.crmChannel) return resource?.crmChannel
  return resource?.serviceId == 'Call_HP' ? 'HPXWin_Phone' : 'HPXWin_Chat'
}

const getCreateCaseRequest = (
  resource,
  values,
  device,
  profile,
  isMobile
): ICaseRequest => {
  const caseRequest: ICaseRequest = {
    CaseExchange: {
      IncomingChannel: _specifyChatChannel(resource),
      Intent: values?.intent[0],
      ChatPBRQueue: resource.chatQueue,
      ChatQueue: resource.chatQueue,
      HWIntent: '1',
      Subject: values?.type[0],
      Description: values?.problem,

      Account: {
        AccountName: `${profile?.FirstName} ${profile?.LastName}`,
        Address: {
          Country: device?.DeviceInfo?.locale?.country
        }
      },
      Contact: [
        {
          ContactType: 'Primary',
          FirstName: profile?.FirstName,
          LastName: profile?.LastName,
          PrimaryEmailAddress: profile?.Email,
          Address: {
            Country: device?.DeviceInfo?.locale?.country
          }
        }
      ],
      Asset: {
        SerialNumber: device?.SerialNumber,
        ProductNumber: device?.ProductNumber
      }
    }
  }
  if (isMobile && values?.phoneNumber) {
    caseRequest['phoneNumber'] = values?.phoneNumber
  }
  return caseRequest
}

export default useCreateCase
