import { useState } from 'react'

function useForm(
  initialState = {},
  validations = [],
  isMobile,
  onSubmit = (val) => val,
  serviceId
) {
  // Add the 'onSubmit' argument
  const { isValid: initialIsValid, errors: initialErrors } = validate(
    validations,
    initialState
  )
  const [values, setValues] = useState(initialState)
  const [errors, setErrors] = useState(initialErrors)
  const [isValid, setValid] = useState(initialIsValid)
  const [touched, setTouched] = useState({})
  const changeHandler = (event, key = null) => {
    let newValues = { ...values }
    if (event.target && event.target.type) {
      switch (event.target.type) {
        case 'checkbox': {
          newValues = { ...values, [event.target.name]: event.target.checked }
          break
        }
        case 'textarea': {
          newValues = { ...values, [event.target.name]: event.target.value }
          break
        }
      }
    } else if (event.value && key)
      newValues = { ...values, [key]: [event.label], intent: [event.value] }

    const { isValid, errors } = validate(validations, newValues)
    setValues(newValues)
    setValid(isValid)
    setErrors(errors)
    setTouched({ ...touched, [event.target ? event.target.name : key]: true })
  }
  // Add this
  const submitHandler = (event) => {
    event.preventDefault()
    onSubmit(values)
  }

  function validate(validations, values) {
    const errors = validations
      .map((validation) => validation(values))
      .filter((validation) => {
        return typeof validation === 'object' && !validation?.phoneNumber
      })
    return {
      isValid: errors.length === 0,
      errors: errors.reduce((errors, error) => ({ ...errors, ...error }), {})
    }
  }

  const fnTouched = (event, key) => {
    setTouched((prevVal) => {
      return { ...prevVal, [event.target ? event.target.name : key]: true }
    })
  }

  const resetForm = () => {
    setValues(initialState)
    setErrors(initialErrors)
    setTouched({})
    setValid(false)
  }

  return {
    values,
    resetForm,
    changeHandler,
    isValid,
    setValid,
    errors,
    touched,
    submitHandler,
    fnTouched
  } // Add 'submitHandler'
}

export default useForm
