import { useContext } from 'react'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import { CoptorAuth } from '@jarvis/web-support-commons/dist/methone/authorization'
import { IProfile } from '@jarvis/web-support-commons/dist/methone'
import { StoreStateType } from '../types/store'
import { LocalizationType } from '../types/localization'

interface IAuthProvider {
  setAuthToken: () => CoptorAuth | string
  checkIsLoggedIn: () => boolean
  checkAuth: () => boolean
  setIsSignedIn: (isSignedIn: boolean) => void
  isSignedIn: boolean
  auth: CoptorAuth | string
  profile: IProfile
}

interface IRefreshProvider {
  refreshList: IRefreshList
  refreshComponent: (val: IRefreshList) => void
}

interface IRefreshList {
  [key: string]: unknown
}
export interface IEnvironment {
  platform: string
  isNative: boolean
  isMobile: boolean
}
interface IContext {
  authProvider?: IAuthProvider
  refreshProvider?: IRefreshProvider
  formatAutoId?: () => string
  store?: StoreStateType
  dir?: string
  localization?: LocalizationType
  environment?: IEnvironment
  navigation?: any
  navigatePath?: string
}

const useContextAPI = () => {
  const {
    refreshProvider,
    authProvider,
    store,
    formatAutoId,
    dir,
    localization,
    environment,
    navigation,
    navigatePath
  } = useContext<IContext>(RootContext)
  return {
    refreshProvider,
    authProvider,
    store,
    formatAutoId,
    dir,
    localization,
    environment,
    navigation,
    navigatePath
  }
}

export default useContextAPI
