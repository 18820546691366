import useContextAPI from './useContextAPI'

const useIntl = () => {
  const { localization } = useContextAPI()

  const getDateLocale = (
    date,
    locale: string | undefined = localization?.locale
  ) => {
    try {
      return new Intl.DateTimeFormat(locale).format(new Date(date))
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  const getNumberLocale = (
    num: number,
    locale: string | undefined = localization?.locale
  ) => {
    try {
      return new Intl.NumberFormat(locale).format(num) // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  return {
    getDateLocale,
    getNumberLocale
  }
}

export default useIntl
