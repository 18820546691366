import React from 'react'
import Button from '@veneer/core/dist/scripts/button'
import { formatAutoId } from '@jarvis/react-hpx-support-shared/dist/utils/automation'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import useIntl from '../../hooks/useIntl'
import {
  Row,
  Column,
  Value,
  Label,
  Line,
  Container
} from '../CreateCase/styles'
import useContextAPI from '../../hooks/useContextAPI'
import { withAnalytics } from '@veneer/analytics'
import { TestIDPrefix } from '../../utils/constants'
import { genTestId } from '../../utils'
const TrackerButton = withAnalytics(Button)

const CaseView = (props) => {
  console.log(props)
  const {
    caseDetails,
    resource,
    setIsSubmitted,
    setCreatedCase,
    authProvider,
    setOpenChat,
    setCaseDetails,
    device
  } = props
  const { profile } = authProvider
  const { t } = useI18n()
  const { getDateLocale } = useIntl()
  const { environment } = useContextAPI()
  const { isMobile } = environment || {}

  const CASE_STATUS = {
    0: t('common.open'),
    1: t('common.closed'),
    2: t('common.requestClosure')
  }
  return (
    <Container>
      <Column flex="1">
        {isMobile && (
          <>
            <Row className="m-t-8">
              <Value
                className="caption"
                id={formatAutoId('case-created-label')}
              >
                {t('speakToAgent.phoneCase.yourCaseHasCreated')}
              </Value>
            </Row>
            <Row className="m-t-8">
              <Label
                className="caption colorForegroundMedium"
                id={formatAutoId('case-created-value')}
              >
                {t('speakToAgent.phoneCase.writeDownCaseNumber')}
              </Label>
            </Row>

            <Row className="m-t-32">
              <Button
                id={formatAutoId('button-phone-case-done')}
                onClick={() => {
                  window.location.href = `tel:${resource?.PhoneGroups[0].Phone}`
                }}
                style={{ flex: 1 }}
              >
                {`Call-${resource?.PhoneGroups[0]?.Phone}`}
              </Button>
            </Row>
            <Row className="m-t-24">
              <Line></Line>
            </Row>
            <Row className="m-t-24">
              <Label
                className="caption colorForegroundMedium"
                id={formatAutoId('case-created-value')}
              >
                {t('speakToAgent.callHPTechDetail')}
              </Label>
            </Row>
          </>
        )}
        <Row className={isMobile ? 'm-t-24' : ''}>
          <Value className="label" id={formatAutoId('profile-name')}>
            {profile?.FirstName}&nbsp; {profile?.LastName}
          </Value>
        </Row>
        <Row className={isMobile ? 'm-t-8' : 'm-t-4'}>
          <Value className="caption" id={formatAutoId('profile-email')}>
            {profile?.Email}
          </Value>
        </Row>
        <Row className={isMobile ? 'm-t-24' : 'm-t-16'}>
          <Label className="caption" id={formatAutoId('case-id-label')}>
            {t('speakToAgent.phoneCase.caseID')}
          </Label>
          <Value
            id={formatAutoId('case-id-value')}
            className="caption"
            x-ms-format-detection="none"
          >
            {caseDetails?.Case?.CaseID}
          </Value>
        </Row>
        <Row className={isMobile ? 'm-t-8' : 'm-t-4'}>
          <Label className="caption" id={formatAutoId('case-date-label')}>
            {t('speakToAgent.phoneCase.caseCreated')}
          </Label>
          <Value className="caption" id={formatAutoId('case-date-value')}>
            {getDateLocale(caseDetails?.Case?.CreatedDateTime)}
          </Value>
        </Row>
        <Row className={isMobile ? 'm-t-8' : 'm-t-4'}>
          <Label className="caption" id={formatAutoId('case-status-label')}>
            {t('speakToAgent.phoneCase.status')}
          </Label>
          <Value className="caption" id={formatAutoId('case-status-value')}>
            {CASE_STATUS[caseDetails?.Case?.Status]}
          </Value>
        </Row>

        <Row className={isMobile ? 'm-t-8' : 'm-t-4'}>
          <Label className="caption" id={formatAutoId('case-pn-label')}>
            {t('common.productNumber', 'Product number')}
          </Label>
          <Value className="caption" id={formatAutoId('case-pn-value')}>
            {caseDetails?.Case?.ProductNumber}
          </Value>
        </Row>
        <Row className={isMobile ? 'm-t-8' : 'm-t-4'}>
          <Label className="caption" id={formatAutoId('case-sn-label')}>
            {t('speakToAgent.phoneCase.serialNumber')}
          </Label>
          <Value className="caption" id={formatAutoId('case-sn-value')}>
            {caseDetails?.Case?.SerialNumber}
          </Value>
        </Row>
        <Row className="m-t-16">
          <Value className="caption" id={formatAutoId('case-desc-label')}>
            {t('speakToAgent.phoneCase.description')}
          </Value>
        </Row>
        <Row className="m-t-8">
          <Value
            className="caption colorForegroundMedium"
            id={formatAutoId('case-desc-value')}
          >
            <p className="caption break-text">
              {caseDetails?.Case?.Description}
            </p>
          </Value>
        </Row>

        {!isMobile && (
          <>
            <Row className="m-t-24">
              <Line></Line>
            </Row>
            <Row className="m-t-24">
              <Value
                className="caption"
                id={formatAutoId('case-created-label')}
              >
                {t('speakToAgent.phoneCase.yourCaseHasCreated')}
              </Value>
            </Row>
            {!device?.IsSubscriptionDevice && (
              <Row className="m-t-8">
                <Label
                  className="caption colorForegroundMedium"
                  id={formatAutoId('case-created-value')}
                >
                  {t('speakToAgent.phoneCase.writeDownCaseNumber')}
                </Label>
              </Row>
            )}
          </>
        )}
        {resource?.PhoneGroups &&
          !isMobile &&
          resource?.PhoneGroups?.map((phone, index) => {
            return (
              <>
                {phone.Prefix && (
                  <Row className="m-t-24">
                    <Value
                      className="body-large"
                      id={formatAutoId(`case-prefix-value-${index}`)}
                    >
                      {phone.Prefix}
                    </Value>
                  </Row>
                )}
                <Row className="m-t-24">
                  <Value className="body-large">
                    <p
                      id={formatAutoId(`case-phone-value-${index}`)}
                      // eslint-disable-next-line react/no-unknown-property
                      x-ms-format-detection="none"
                      dangerouslySetInnerHTML={{
                        __html: phone.Phone
                      }}
                    ></p>
                  </Value>
                </Row>{' '}
                {phone.Suffix && (
                  <Row className="m-t-24">
                    <Value className="body-large">
                      <p
                        id={formatAutoId(`case-suffix-value-${index}`)}
                        dangerouslySetInnerHTML={{
                          __html: phone.Suffix
                        }}
                      ></p>
                    </Value>
                  </Row>
                )}
              </>
            )
          })}

        {!isMobile && (
          <Row className="m-t-32">
            <TrackerButton
              id={formatAutoId('button-phone-case-done')}
              trackId={genTestId(`${TestIDPrefix}`, `caseCreatedDone`)}
              trackCategory={`${resource?.serviceId?.toLowerCase()}_${
                caseDetails?.Case?.CaseID
              }`}
              onClick={() => {
                setCaseDetails?.(null)
                setCreatedCase?.(null)
                setCaseDetails?.(null)
                setOpenChat?.(false)
                setIsSubmitted?.(false)
              }}
              style={{ flex: 1 }}
            >
              {t('speakToAgent.phoneCase.done')}
            </TrackerButton>
          </Row>
        )}
      </Column>
    </Container>
  )
}

export default CaseView
