import React, { useEffect, useRef } from 'react'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import { Text, Row, Column, CheckBox, Form, TrackerButton } from './styles'
import Select from '@veneer/core/dist/esm/scripts/select'
import TextArea from '@veneer/core/dist/esm/scripts/text_area'
import useForm from '../../hooks/useForm'
import useSelect from '../../hooks/useSelect'
import { launchUrlFromCommand } from '@jarvis/web-support-commons'
import useContextAPI from '../../hooks/useContextAPI'
import { TestIDPrefix } from '../../utils/constants'
import { genTestId } from '../../utils'
interface IFormProps {
  changeHandler: (e: unknown, key?: string) => void
  submitHandler: (e: unknown) => void
  isValid: boolean
  values: IValues
  resetForm: () => void
  errors: IValues
  touched: IValues
  fnTouched: (e: unknown, key?: string) => void
}

interface IValues {
  type?: any
  intent?: any
  problem?: string
  phoneNumber?: string
  privacy?: boolean
}

const initialState: IValues = {
  type: [],
  intent: [],
  problem: '',
  phoneNumber: '',
  privacy: false
}

const getItems = (t) => {
  return [
    {
      value: t('common.typeDropdown.type1', { lng: 'en_US' }),
      label: t('common.typeDropdown.type1')
    },
    {
      value: t('common.typeDropdown.type2', { lng: 'en_US' }),
      label: t('common.typeDropdown.type2')
    },
    {
      value: t('common.typeDropdown.type3', { lng: 'en_US' }),
      label: t('common.typeDropdown.type3')
    },
    {
      value: t('common.typeDropdown.type4', { lng: 'en_US' }),
      label: t('common.typeDropdown.type4')
    },
    {
      value: t('common.typeDropdown.type5', { lng: 'en_US' }),
      label: t('common.typeDropdown.type5')
    },
    {
      value: t('common.typeDropdown.type6', { lng: 'en_US' }),
      label: t('common.typeDropdown.type6')
    },
    {
      value: t('common.typeDropdown.type7', { lng: 'en_US' }),
      label: t('common.typeDropdown.type7')
    },
    {
      value: t('common.typeDropdown.type8', { lng: 'en_US' }),
      label: t('common.typeDropdown.type8')
    }
  ]
}

const validations = [
  ({ type }) => isRequired(type) || { type: 'common.selectCategory' },
  ({ problem }) =>
    isRequired(problem) || {
      problem: 'common.checkTheProblemAndTryAgain'
    },
  ({ phoneNumber }) =>
    isRequired(phoneNumber) || {
      phoneNumber: 'common.checkThePhoneNumberAndTryAgain'
    },
  ({ privacy }) => privacy || { privacy: 'Required' }
]

function isRequired(value) {
  return (
    value != null &&
    (typeof value === 'string' ? value?.trim().length > 0 : value.length > 0)
  )
}

const CreateCase = (props) => {
  console.log('props', props)
  const { t } = useI18n()
  const {
    setOpenChat,
    resource,
    device,
    authProvider,
    formatAutoId,
    setLoading,
    modal,
    createCase,
    setCreatedCase,
    creating,
    setCreating
  } = props
  const { profile } = authProvider
  const typeClickRef = useRef(null)
  const typeParentRef = useRef<any>()
  const options = getItems(t)
  const { selProps, typeRef } = useSelect()
  const { environment } = useContextAPI()
  const { isMobile } = environment || {}
  const {
    changeHandler,
    submitHandler,
    values,
    resetForm,
    errors,
    isValid,
    touched,
    fnTouched
  } = useForm(
    initialState,
    validations,
    isMobile,
    createCaseCallback,
    resource?.serviceId
  ) as IFormProps

  const handleClickOutside = (e) => {
    const elem = document.activeElement
    if (elem?.getAttribute('id') == 'type-listbox') {
      typeClickRef.current = true
      // hack for veneer select align issue on DOM/Window size change
      window.dispatchEvent(new Event('resize'))
      setTimeout(() => window.dispatchEvent(new Event('resize')), 1)
    } else {
      if (typeClickRef.current) {
        typeClickRef.current = false
        // hack for veneer select focus-out validation
        if (!typeRef?.current?.node?.value) {
          fnTouched(typeRef?.current?.node, 'type')
        }
        typeParentRef.current?.focus()
      }
    }
  }

  useEffect(() => {
    document?.removeEventListener('click', handleClickOutside)
    document?.addEventListener('click', handleClickOutside)
    window.onresize = () => {
      console.log('resize')
      const elem = document.activeElement as HTMLElement
      if (elem?.getAttribute('id') == 'type-listbox') {
        typeParentRef.current?.focus()
        typeParentRef.current?.click()
      }
    }

    return () => {
      document?.removeEventListener('click', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // a submit function that will execute upon form submission
  async function createCaseCallback() {
    setCreating(true)
    const response = await createCase({
      resource,
      values,
      device,
      profile
    })
    if (response?.FaultItemList && response?.FaultItemList.length == 0) {
      if (setCreatedCase) setCreatedCase(response)
      if (resource?.serviceId != 'Call_HP') setOpenChat('createCase', false)
    }
    setCreating(false)
  }

  useEffect(() => {
    if (setLoading && !isMobile) setLoading('createCase', false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal])

  return (
    <>
      {resource && (
        <>
          <Form onSubmit={submitHandler}>
            {(resource?.serviceId == 'OCChat' ||
              resource?.serviceId == 'OmniChat' ||
              resource?.serviceId == 'Chat') && (
              <>
                <Row className="m-b-12">
                  <Text
                    className="caption"
                    dangerouslySetInnerHTML={{
                      __html: isMobile
                        ? t('common.describTheIssueClickChatNow_Mobile')
                        : t('common.describTheIssueClickChatNow')
                    }}
                  ></Text>
                </Row>
              </>
            )}
            {isMobile && resource?.serviceId == 'Call_HP' && (
              <Row className="m-b-12">
                <Text
                  className="caption"
                  dangerouslySetInnerHTML={{
                    __html: t('common.callAgentDescription')
                  }}
                ></Text>
              </Row>
            )}
            {isMobile && resource?.serviceId == 'Call_HP' && (
              <Row className="m-b-12">
                <Text
                  className="caption"
                  dangerouslySetInnerHTML={{
                    __html: t('common.speakWithAgent')
                  }}
                ></Text>
              </Row>
            )}
            <Row className="m-b-8">
              <Text
                className="label m-r-24"
                id={formatAutoId(`create-case-profile-name`)}
                tabindex={-1}
              >
                {profile?.FirstName}&nbsp;{profile?.LastName}
              </Text>
              {/* <IconPencil size={14} color="colorHpBlue6"></IconPencil>*/}
            </Row>
            <Row className="m-b-12">
              <Text
                className="caption"
                id={formatAutoId(`create-case-profile-email`)}
                tabindex={-1}
              >
                {profile?.Email}
              </Text>
            </Row>
            {isMobile &&
              (resource?.serviceId == 'OCChat' ||
                resource?.serviceId == 'OmniChat') && (
                <Row className="m-b-12">
                  <Column>
                    <Text className="caption">
                      {t('common.phoneNumberInput.name')}
                    </Text>
                    <TextArea
                      name="phoneNumber"
                      id="phoneNumber"
                      required
                      value={values?.phoneNumber}
                      maxLength={1024}
                      height={'0'}
                      error={
                        touched?.phoneNumber && errors?.phoneNumber
                          ? true
                          : false
                      }
                      helperText={
                        t(touched?.phoneNumber) && t(errors?.phoneNumber)
                      }
                      onBlur={(e) => changeHandler(e)}
                      onChange={(v, e) => changeHandler(e)}
                      placeholder={'(   ) ____  -  _________'}
                      className="label-small input"
                    />
                  </Column>
                </Row>
              )}
            <Row className="m-b-12">
              <Column className="max-width" tabIndex={-1} ref={typeParentRef}>
                <Text className="caption">{t('common.typeDropdown.name')}</Text>
                <Select
                  ref={typeRef}
                  i18n={{
                    clear: '',
                    noResults: '',
                    open: '',
                    searchPlaceholder: '',
                    selected: '',
                    showingResult: '',
                    showingResults: '',
                    unselected: ''
                  }}
                  className="label-small"
                  clearIcon={false}
                  options={options}
                  id="type"
                  name="type"
                  label={
                    values?.type?.length == 0 ? t('common.selectCategory') : ''
                  }
                  error={touched?.type && errors?.type ? true : false}
                  helperText={t(touched?.type) && t(errors?.type)}
                  placement={selProps?.placement}
                  helperTextVisibility="auto"
                  onChange={(e) => changeHandler(e, 'type')}
                  value={values?.intent}
                  visibleOptions={selProps?.visibleOptions}
                />
              </Column>
            </Row>
            <Row className="m-b-12">
              <Column>
                <Text className="caption">{t('common.problemInput.name')}</Text>
                <TextArea
                  name="problem"
                  id="problem"
                  required
                  value={values?.problem}
                  maxLength={1024}
                  error={touched?.problem && errors?.problem ? true : false}
                  helperText={t(touched?.problem) && t(errors?.problem)}
                  onBlur={(e) => changeHandler(e)}
                  onChange={(v, e) => changeHandler(e)}
                  placeholder={t('common.problemInput.text')}
                  className="label-small input"
                />
              </Column>
            </Row>
            <Row className="m-b-20">
              <CheckBox
                className={`checkBox ${values?.privacy ? 'checked' : ''}`}
                id="privacy"
                checked={values?.privacy}
                name="privacy"
                onChange={(e) => changeHandler(e)}
                error={touched?.privacy && errors?.privacy ? true : false}
                label={
                  <>
                    <Text className="label-small foreground-medium">
                      {resource?.serviceId == 'OCChat' ||
                      resource?.serviceId == 'OmniChat'
                        ? t('common.privacyCheck')
                        : t('common.privacyTermsConditionsCheckMessage')}
                      &nbsp;
                      <a
                        className="flex-inline"
                        id={formatAutoId(`privacy-link`)}
                        tabIndex={0}
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          launchUrlFromCommand(
                            '"HPSALauncher.exe" hpprivacy %serialNumber% /UseCCC',
                            device
                          )
                        }}
                      >
                        {t('common.privacyLink')}
                      </a>
                    </Text>
                  </>
                }
              />
            </Row>
            {(resource?.serviceId == 'OCChat' ||
              resource?.serviceId == 'OmniChat') && (
              <>
                <Row className="m-b-12">
                  <Column>
                    <TrackerButton
                      id={formatAutoId(
                        `create-case-save-${resource?.serviceId}`
                      )}
                      trackId={genTestId(
                        `${TestIDPrefix}`,
                        `${resource?.serviceId?.toLowerCase()}`
                      )}
                      trackCategory={`chatNow`}
                      type="submit"
                      loading={creating}
                      disabled={!isValid}
                      className="caption"
                      onClick={() => null}
                    >
                      {t('chatWithAgent.chatWindow.chatNow')}
                    </TrackerButton>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <TrackerButton
                      id={formatAutoId(
                        `create-case-cancel-${resource?.serviceId}`
                      )}
                      trackId={genTestId(`${TestIDPrefix}`, `Cancel`)}
                      trackCategory={`cancelGetCahtNow`}
                      disabled={creating}
                      appearance="secondary"
                      onClick={() => {
                        if (resource?.serviceId == 'Call_HP') setOpenChat(false)
                        else setOpenChat('createCase', false)
                      }}
                      className="caption secondary"
                    >
                      {t('common.cancel')}
                    </TrackerButton>
                  </Column>
                </Row>
              </>
            )}
            {resource?.serviceId == 'Call_HP' && (
              <Row className="m-b-12 buttons">
                <Column>
                  <TrackerButton
                    id={formatAutoId(`create-case-save-${resource?.serviceId}`)}
                    trackId={genTestId(
                      `${TestIDPrefix}`,
                      `${resource?.serviceId?.toLowerCase()}`
                    )}
                    type="submit"
                    trackCategory={`getPhoneNumber`}
                    loading={creating}
                    disabled={!isValid}
                    className="caption"
                    onClick={() => null}
                  >
                    {t('speakToAgent.getPhoneNumber')}
                  </TrackerButton>
                </Column>
                <Column className="m-l-12">
                  <TrackerButton
                    className="caption secondary"
                    id={formatAutoId(
                      `create-case-cancel-${resource?.serviceId}`
                    )}
                    trackId={genTestId(`${TestIDPrefix}`, `Cancel`)}
                    trackCategory={`cancelGetPhoneNumber`}
                    disabled={creating}
                    appearance="secondary"
                    onClick={() => {
                      resetForm()
                      if (resource?.serviceId == 'Call_HP') setOpenChat(false)
                      else setOpenChat('createCase', false)
                    }}
                  >
                    {t('common.cancel')}
                  </TrackerButton>
                </Column>
              </Row>
            )}
          </Form>
        </>
      )}
    </>
  )
}

export default React.memo(CreateCase)
