import React, { useContext } from 'react'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import { ThemeProvider } from 'styled-components'
import { useTheme } from '@veneer/theme'
import CreateCase from './CreateCase'
import CaseView from './CaseView'
import { LocalizationType } from '../types/localization'
import useCreateCase from '../hooks/useCreateCase'
const Case = React.lazy(() => import('../mobile/index'))
interface IContext {
  dir?: string
  environment?: any
  localization?: LocalizationType
}

const RootComponent = (props) => {
  const theme = useTheme()
  const { environment, dir } = useContext<IContext>(RootContext)
  const { setIsSubmitted, resource, device } = props
  const {
    createCase,
    setCreatedCase,
    caseDetails,
    setCaseDetails,
    setCreating,
    creating
  } = useCreateCase({ setIsSubmitted, resource, device })

  return (
    <ThemeProvider theme={{ ...environment, ...theme, dir }}>
      {environment.isMobile ? (
        <Case
          createCase={createCase}
          setCreatedCase={setCreatedCase}
          creating={creating}
          setCreating={setCreating}
          {...props}
        />
      ) : (
        <>
          {(!caseDetails || creating) && (
            <CreateCase
              {...props}
              createCase={createCase}
              setCreatedCase={setCreatedCase}
              creating={creating}
              setCreating={setCreating}
            />
          )}
          {caseDetails && !creating && (
            <CaseView
              {...props}
              setCreatedCase={setCreatedCase}
              caseDetails={caseDetails}
              setCaseDetails={setCaseDetails}
              device={device}
            ></CaseView>
          )}
        </>
      )}
    </ThemeProvider>
  )
}

export default RootComponent
