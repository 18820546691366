export function genTestId(
  moduleId: string,
  componentId: string,
  suffix?: string
): string {
  const allowedCharsTrkId = /^([A-Z][a-z]+)+?[0-9.]*?$/
  if (moduleId.length > 4) {
    moduleId = moduleId.slice(0, 4)
  }

  if (componentId.length > 28) {
    componentId = componentId.slice(0, 28)
  }
  // JWebTrackId should not be longer than 32 characters because of simpleUi event schema constraints for the 'componentId' field
  const testJWebId = `${moduleId}${componentId}`

  if (!allowedCharsTrkId.test(testJWebId))
    return testJWebId.replace(/[^A-Za-z0-9.]/gi, '')

  return testJWebId
}
