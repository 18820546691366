import { useEffect, useRef, useState } from 'react'
import { SelectPlacement } from '@veneer/core/dist/scripts/select'

const maxItemShow = 8

interface ISelect {
  visibleOptions: number
  placement: SelectPlacement
}

const useSelect = () => {
  const [selProps, setSelProps] = useState<ISelect>({
    visibleOptions: maxItemShow,
    placement: `bottom`
  })
  const typeRef = useRef(null)

  useEffect(() => {
    const setSelectView = () => {
      try {
        if (typeRef.current) {
          const nodeElem = typeRef.current?.node?.parentElement as HTMLElement
          const domRect = nodeElem?.getBoundingClientRect()
          const body = document.body.getBoundingClientRect()
          const bottom = body.bottom - domRect.bottom
          const top = domRect.top
          let _selProps: ISelect = null
          if (bottom > 200 || bottom > top) {
            const showCount = bottom / 40 - 1
            _selProps = {
              visibleOptions:
                showCount >= maxItemShow || showCount <= 0
                  ? maxItemShow
                  : showCount,
              placement: `bottom`
            }
          } else {
            const showCount = top / 40 - 1
            _selProps = {
              visibleOptions:
                showCount >= maxItemShow || showCount <= 0
                  ? maxItemShow
                  : showCount,
              placement: `top`
            }
          }
          setSelProps(_selProps)
          const popover: HTMLElement =
            document.querySelector(`[data-popper-placement="bottom"] > div`) ||
            document.querySelector(`[data-popper-placement="top"] > div`)
          if (popover) {
            popover.style.minWidth = `${nodeElem.offsetWidth}px`
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
    window.addEventListener('resize', setSelectView)
    setSelectView()

    return () => {
      window.removeEventListener('resize', setSelectView)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    selProps,
    typeRef
  }
}

export default useSelect
