const breakpoints = {
  L: '1008px',
  M: '640px'
}

const rtl =
  (left, right) =>
  ({ theme }) =>
    theme.dir == 'rtl' ? right : left
export const left = rtl('left', 'right')
export const right = rtl('right', 'left')

const sizeL = parseInt(breakpoints.L.replace('px', ''))
const sizeM = parseInt(breakpoints.M.replace('px', ''))
const maxM = sizeL - 1
const minM = sizeM + 1

export const HPXTheme = {
  breakpoints,
  mediaQueries: {
    L: `@media only screen and (min-width: ${breakpoints.L})`,
    M: `@media only screen and (max-width: ${maxM}px) and (min-width: ${minM}px)`,
    S: `@media only screen and (max-width: ${breakpoints.M})`
  }
}
