export const Support = 'Support'
export const SupportDevice = 'SupportDevice'
export const BaseScreenPath = '/HpxWinClient'
export const DefaultScreenName = 'DeviceSupport'
export const DeviceScreenName = 'SupportCreatecase'

export const TestIDPrefix = 'Supp'
export const ContainerComponent = 'CreatecaseComponent'
export const MFEname = 'DeviceSupportCreatecase'
export const DeviceComponent = 'DeviceComponent'
